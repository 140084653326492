@import "~@nosferatu500/react-sortable-tree/style.css";

.rst__tree {
    [data-viewport-type="element"] {
        position: unset !important;
    }

    .rst__virtualScrollOverride {
        overflow: unset !important;
    }
}

.rst__node {
    display: flex;
    margin-bottom: 8px;

    .rst__lineBlock {
        flex: 0 0 auto;
    }

    .rst__nodeContent {
        flex: 0 1 auto;
        left: auto !important;
        position: relative !important;
        top: auto !important;
        width: 100%;
    }

    .custom-switch .custom-control-label {
        &::before {
            left: -3rem;
        }

        &::after {
            left: calc(-3rem + 4px);
        }
    }
}

.rst__lineHalfHorizontalRight::before,
.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after {
    background-color: transparent;
}

.rst__rowWrapper {
    padding: 0;
}

.rst__node {
    height: 44px !important;

    &:not(:last-child) {
        margin-bottom: 0.5rem;
    }
}

.rst__rowLabel {
    padding-right: 0;
    width: 100%;
}

.rst__rowWrapper {
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    background: transparent;
    border-radius: $border-radius;
}

.rst__rowContents {
    border-color: $input-border-color;
    border-radius: $border-radius;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: none;
}

.rst__moveHandle {
    border-color: $input-border-color;
    border-radius: $border-radius;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: none;
    background-size: 0.5rem !important;
    background: #fff url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxODYuNjciIGhlaWdodD0iNDg1LjMzOCIgdmlld0JveD0iMCAwIDE4Ni42NyA0ODUuMzM4Ij48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjU2LjY2NiAtMzcuMzMyKSI+PHBhdGggZD0iTTM1MCw1MjIuNjdBMTguNjY3LDE4LjY2NywwLDAsMSwzMzEuMzMyLDUwNFY1NmExOC42NjgsMTguNjY4LDAsMCwxLDM3LjMzNiwwVjUwNEExOC42NywxOC42NywwLDAsMSwzNTAsNTIyLjY3WiIvPjxwYXRoIGQ9Ik0zNTAsNTIyLjY3YTE4LjYwNSwxOC42MDUsMCwwLDEtMTMuMi01LjQ2NWwtNzQuNjY4LTc0LjY2OGExOC42NjYsMTguNjY2LDAsMSwxLDI2LjQtMjYuNEwzNTAsNDc3LjZsNjEuNDY1LTYxLjQ2OWExOC42NjYsMTguNjY2LDAsMSwxLDI2LjQsMjYuNEwzNjMuMiw1MTcuMkExOC42LDE4LjYsMCwwLDEsMzUwLDUyMi42N1oiLz48cGF0aCBkPSJNNDI0LjY3LDE0OS4zM2ExOC42MDksMTguNjA5LDAsMCwxLTEzLjItNS40NjlMMzUwLDgyLjRsLTYxLjQ2OSw2MS40NjlhMTguNjY2LDE4LjY2NiwwLDAsMS0yNi40LTI2LjRMMzM2LjgsNDIuOGExOC42NjcsMTguNjY3LDAsMCwxLDI2LjQsMGw3NC42NjgsNzQuNjY4YTE4LjY2NCwxOC42NjQsMCwwLDEtMTMuMiwzMS44NjNaIi8+PC9nPjwvc3ZnPg==") no-repeat center;
}

.rst__collapseButton {
    background: transparent url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJjYXJldC1kb3duIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtY2FyZXQtZG93biBmYS13LTEwIiByb2xlPSJpbWciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDMyMCA1MTIiPjxwYXRoIGZpbGw9ImN1cnJlbnRDb2xvciIgZD0iTTMxLjMgMTkyaDI1Ny4zYzE3LjggMCAyNi43IDIxLjUgMTQuMSAzNC4xTDE3NC4xIDM1NC44Yy03LjggNy44LTIwLjUgNy44LTI4LjMgMEwxNy4yIDIyNi4xQzQuNiAyMTMuNSAxMy41IDE5MiAzMS4zIDE5MnoiPjwvcGF0aD48L3N2Zz4=") no-repeat center;
}

.rst__expandButton {
    background: transparent url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJjYXJldC1yaWdodCIgY2xhc3M9InN2Zy1pbmxpbmUtLWZhIGZhLWNhcmV0LXJpZ2h0IGZhLXctNiIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOTIgNTEyIj48cGF0aCBmaWxsPSJjdXJyZW50Q29sb3IiIGQ9Ik0wIDM4NC42NjJWMTI3LjMzOGMwLTE3LjgxOCAyMS41NDMtMjYuNzQxIDM0LjE0Mi0xNC4xNDJsMTI4LjY2MiAxMjguNjYyYzcuODEgNy44MSA3LjgxIDIwLjQ3NCAwIDI4LjI4NEwzNC4xNDIgMzk4LjgwNEMyMS41NDMgNDExLjQwNCAwIDQwMi40OCAwIDM4NC42NjJ6Ij48L3BhdGg+PC9zdmc+") no-repeat center;
}

.rst__collapseButton,
.rst__expandButton {
    box-shadow: none;
    border: 0;
    height: 20px;
    width: 20px;
    border-radius: 0;
    left: -1rem !important;

    &:hover {
        background-size: unset !important;
        height: 20px;
        width: 20px;
    }
}

.rst__nodeContent {
    margin-left: -20px;
}

.rst__rowTitle {
    font-weight: normal;
}

.rst__rowTitle p {
    margin: 0;
}

.rst__rowLandingPad::before,
.rst__rowCancelPad::before {
    background-color: transparent;
    border: 2px dashed $gray-500;
    border-radius: $border-radius;
}

.rst__highlightLineVertical::before {
    background-color: $gray-500;
    width: 2px;
}

.rst__highlightLineVertical::after {
    animation: none;
    border: none;
}

.rst__highlightTopLeftCorner::before {
    border-top: solid 2px $gray-500;
    border-left: solid 2px $gray-500;
}

.rst__rtl.rst__highlightTopLeftCorner::before {
    border-right: solid 2px $gray-500;
    border-left: none;
    left: 0;
    right: initial;
}

.rst__highlightBottomLeftCorner {
    z-index: 3;
}

.rst__highlightBottomLeftCorner::before {
    border-bottom: solid 2px $gray-500;
    border-left: solid 2px $gray-500;
}

.rst__rtl.rst__highlightBottomLeftCorner::before {
    border-right: solid 2px $gray-500;
}

.rst__highlightBottomLeftCorner::after {
    border-left: 12px solid $gray-500;
}

.rst__rtl.rst__highlightBottomLeftCorner::after {
    border-right: 12px solid $gray-500;
}