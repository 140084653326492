﻿@import "dashlite_variables";

.banner-component-group {
    margin-bottom: 1.5rem;
}

.banner-component-group:last-child {
    margin-bottom: 0;
}

.data-table-image-column {
    border: 1px solid $border-light;
    margin: 16px 0;
    padding: 4px;
}

.lean-data-table-empty {
    display: flex;
    flex-direction: column;
    padding: 2rem 0;
    text-align: center;
}

.lean-data-table-empty .icon {
    margin-bottom: 10px;
}

.emptyDataTable {
    [role="table"] {
        display: none;
    }
}

.icon {
    &.lean-icon-size- {
        &sm {
            font-size: 15px;
        }

        &md {
            font-size: 20px;
        }

        &lg {
            font-size: 40px;
        }

        &xl {
            font-size: 60px;
        }

        &xxl {
            font-size: 80px;
        }
    }
}

.lean-wizard li {
    border-bottom: 1px solid $border-light;
    margin-bottom: 15px;
    padding-bottom: 5px;
}

.lean-wizard li:last-child {
    margin: 0px;
}

.lean-wizard li.active {
    border-bottom: 2px solid $accent-color;
}

.lean-wizard li.active h5,
.lean-wizard li.active span {
    color: $accent-color;
}

.lean-wizard li h5,
.lean-wizard li span {
    color: $base-400;
    font-size: $fs-base;
    font-weight: $fw-normal;
}

.lean-wizard li span {
    font-weight: $fw-bold;
}

.lean-select-product-option {
    display: flex;
}

.lean-select-product-option img {
    height: 64px;
    margin-right: 10px;
}

.lean-select-product-option h3 {
    font-size: $fs-base;
    font-weight: $fw-bold;
    margin-bottom: 5px;
}

.lean-select-product-option span {
    display: block;
}

.lean-catalog-product-card {
    border: 1px solid $input-border-color;
    border-radius: $input-border-radius;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    text-align: center;
}

.lean-catalog-product-card .info {
    display: flex;
    flex-direction: column;
    margin: 8px 0;
}

.lean-catalog-product-card .title {
    font-size: $fs-base;
    font-weight: $fw-bold;
    margin-bottom: 8px;
}

.lean-product-selector {
    align-items: center;
    border: 1px solid $input-border-color;
    border-radius: $input-border-radius;
    display: flex;
    min-height: 38px;
    padding: 0 8px;
}

.lean-product-selector p,
.lean-product-selector span {
    align-items: center;
    display: flex;
    font-size: $input-font-size;
}

.lean-product-selector p {
    color: #8094ae;
}

.lean-product-selector span {}


.banner-type img {
    width: 80px;
}

.custom-control-full,
.custom-control-full label {
    width: 100%;
    height: 100%;
}


.custom-control-pro {
    padding-left: 0;
    position: relative
}

.custom-control-pro.no-control.checked {
    z-index: 3
}

.custom-control-pro.no-control.focused {
    z-index: 2
}

.custom-control-pro.no-control .custom-control-label {
    padding: .375rem 1.125rem;
    border-width: 2px
}

.custom-control-pro.no-control .custom-control-input:checked~.custom-control-label {
    border-color: #854fff;
    box-shadow: none;
    z-index: 2
}

.custom-control-pro.no-control .custom-control-input:not(:disabled):active~.custom-control-label {
    border-color: #854fff;
    box-shadow: none
}

.custom-control-pro.no-control .custom-control-input[disabled]~.custom-control-label,
.custom-control-pro.no-control .custom-control-input:disabled~.custom-control-label {
    color: #3c4d62
}

.custom-control-pro-block.custom-control {
    display: flex
}

.custom-control-pro-block .custom-control-label {
    width: 100%
}

.custom-control-pro .custom-control-label {
    border: 1px solid #e5e9f2;
    padding: 1.125rem 1.125rem 1.125rem 3.375rem;
    font-size: 13px;
    line-height: 1.25rem;
    border-radius: 4px;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    display: inline-flex;
    align-items: center;
    background-color: #fff
}

.custom-control-pro .custom-control-label::before,
.custom-control-pro .custom-control-label::after {
    top: 50%;
    transform: translateY(-50%);
    left: 1.125rem
}

.custom-control-pro .custom-control-label .icon {
    font-size: 1.4em;
    line-height: inherit
}

.custom-control-pro .custom-control-label .icon-lg {
    font-size: 2.2em
}

.custom-control-pro .custom-control-label>span {
    white-space: nowrap
}

.custom-control-pro .custom-control-label>span:only-child {
    width: 100%
}

.custom-control-pro .custom-control-label .icon+span,
.custom-control-pro .custom-control-label span+.icon {
    padding-left: 8px
}

.custom-control-pro.custom-control-sm {
    padding-left: 0
}

.custom-control-pro.custom-control-sm .custom-control-label {
    padding: .6875rem 1.125rem .6875rem 3rem;
    font-size: 13px;
    line-height: 1.25rem;
    border-radius: 4px
}

.custom-control-pro.custom-control-sm .custom-control-label::before,
.custom-control-pro.custom-control-sm .custom-control-label::after {
    top: 50%;
    transform: translateY(-50%);
    left: 1.125rem
}

.custom-control-pro-icon.custom-control-pro .custom-control-label {
    padding-left: 0;
    padding-right: 0
}

.custom-control-pro-icon.custom-control-pro .custom-control-label .icon {
    text-align: center;
    width: 2.125rem
}

.pro-control.custom-control {
    padding-left: 0
}

.pro-control .custom-control-label {
    padding: 1.5rem;
    border-radius: 4px;
    border: 1px solid #e5e9f2;
    overflow: hidden;
    transition: .3s ease
}

.pro-control .custom-control-label:before,
.pro-control .custom-control-label:after {
    z-index: 9
}

.pro-control .custom-control-label::before {
    border-width: 1px;
    top: 1rem;
    left: 1rem
}

.pro-control .custom-control-label::after {
    top: 1rem;
    left: 1rem
}


.pro-control .custom-control-label .text-center .icon {
    margin-left: auto;
    margin-right: auto
}

.pro-control .custom-control-input:checked~.custom-control-label {
    border-color: #854fff
}

.react-datepicker__current-month:first-letter,
.react-datepicker__day-name:first-letter {
    text-transform: uppercase !important;
}

.lean-loader-backdrop {
    top: 0;
    left: 0;
    opacity: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    z-index: 9999;
    position: fixed;
    visibility: hidden;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    background-color: rgba(0, 0, 0, 0.05);

    &.show {
        opacity: 1;
        visibility: visible;
    }
}

.lean-toast {

    .Toastify__toast-body {
        font-size: 13px;
        color: #526484;
        font-weight: 700;
    }

    .Toastify__toast--success {
        border-color: #9d72ff;
    }

    .Toastify__progress-bar--success {
        background-color: #854fff;
    }
}

.language-dropdown {
    .lean-languageBox {
        height: 22px;
        width: 22px;
    }

    .lean-flagIcon {
        height: auto;
        display: block;
        max-width: 100%;
    }

    .lean-languageList {
        margin: 0;
        padding: 0;
        list-style: none;

        .lean-languageItem {
            display: flex;
            cursor: pointer;
            padding: 8px 16px;
            align-items: center;
            transition: all 0.3s ease-in-out;

            &:hover {
                color: #854fff;
            }

            .lean-flagIcon {
                max-width: 32px;
            }

            &:not(:last-child) {
                border-bottom: 1px solid #e5e9f2;
            }
        }
    }
}


.leanForm-socialMedia {
    .input-group-prepend .input-group-text {
        min-width: 240px;
        text-align: center;
    }
}

.leanFlag-selectWrapper {
    position: fixed;
    right: 16px;
    top: 16px;

    .dropdown-menu-s2 {
        max-width: unset;
        min-width: unset;

        .lean-languageItem {
            &::before {
                position: absolute;
                z-index: -1;
                height: 20px;
                width: 20px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transform-origin: 50% 50%;
                content: "";
                background-color: #e5e9f2;
                border-radius: 50%;
                opacity: 0;
                transition: all 0.3s;
            }

            &:hover::before {
                opacity: 1;
                height: 90%;
                width: 80%;
            }
        }
    }
}

.date-picker-range .input-group-addon {
    justify-content: center;
    padding: 0;
    width: 8%;
}

form .row {
    margin-bottom: 1.2rem !important
}

form .row:last-child {
    margin-bottom: 0 !important
}