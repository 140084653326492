.badge {
    position: relative;
    color: #FFF;
    line-height: $badge-line-height;
    font-family: $base-font-family;
    letter-spacing: 0.01em;
    vertical-align: middle;
    display: inline-flex;

    .icon+span,
    span+.icon {
        padding-left: 4px;
    }

    &-sm {
        padding: $badge-padding-y-sm $badge-padding-x-sm;
        font-size: $badge-font-size-sm;

        &.badge-pill {
            padding: $badge-padding-y-sm $badge-pill-padding-x-sm;
        }
    }

    &-md {
        padding: $badge-padding-y-md $badge-padding-x-md;
        font-size: $badge-font-size-md !important;
    }

    &-lg {
        padding: $badge-padding-y-lg $badge-padding-x-lg;
        font-size: $badge-font-size-lg !important;
    }

    &-xl {
        padding: $badge-padding-y-xl $badge-padding-x-xl;
        font-size: $badge-font-size-xl !important;
    }

    &-light {
        color: $secondary;
    }

    &-lighter {
        color: $secondary-light;
    }

    .icon {
        font-size: 1rem;
    }
}

@each $name,
$value in $theme-colors {
    .badge-#{$name} {
        border-color: $value;
    }
}

$outline-badges: map-merge($theme-colors, ("light": $light-400, "lighter": $light-200 ));

@each $name,
$value in $outline-badges {
    .badge-outline-#{$name} {
        color: $value;
        border: 1px solid mix($value, $white, 60%);
    }
}

$dim-mix: #fff;
$dim-badge: map-merge($theme-colors,
        ("light": $light-400,
            "lighter": $light-200 ));

@each $name,
$color in $dim-badge {
    .badge-dim.badge-#{$name} {
        color: $color;
        background-color: mix($color, $dim-mix, 11%);
        border-color: mix($color, $dim-mix, 11%);
    }

    .badge-dim.badge-outline-#{$name} {
        color: $color;
        background-color: mix($color, $dim-mix, 11%);
        border-color: mix($color, $dim-mix, 40%);
    }
}

.badge-dot {
    display: inline-flex;
    align-items: center;
    background-color: transparent;
    border: none;
    padding-left: $badge-dot-width + $badge-dot-gap;
    padding-right: 0;
    font-size: $fx-sz-12;

    &:before {
        position: absolute;
        content: "";
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        display: inline-block;
        height: $badge-dot-width;
        width: $badge-dot-width;
        margin-top: -1px;
        border-radius: 50%;
        background: currentColor;
    }

    &.has-bg {
        border-radius: 20px;
        padding: 3px ($badge-dot-width + $badge-dot-gap) 3px ($badge-dot-width + $badge-dot-gap)*2;

        &:before {
            left: ($badge-dot-width + $badge-dot-gap);
            margin-top: 0;
        }
    }

    &.badge-sm,
    &.badge-md {
        padding-left: $badge-dot-width-sm + $badge-dot-gap-sm;

        &:before {
            height: $badge-dot-width-sm;
            width: $badge-dot-width-sm;
            margin-top: 0;
        }

        &.has-bg {
            border-radius: 20px;
            padding: 5px ($badge-dot-width-sm + $badge-dot-gap-sm)*.75 5px ($badge-dot-width-sm + $badge-dot-gap-sm)*1.75;

            &:before {
                left: ($badge-dot-width-sm + $badge-dot-gap-sm)*.75;
            }
        }
    }

    &.badge-lg,
    &.badge-xl {
        padding-left: ($badge-dot-width * 2) + ($badge-dot-gap-sm + 4);

        &:before {
            height: ($badge-dot-width * 2);
            width: ($badge-dot-width * 2);
            margin-top: 0;
        }
    }
}

$dot-badge: map-merge($theme-colors,
        ("light": $light-400,
            "lighter": $light-200 ));

@each $name,
$color in $dot-badge {
    .badge-dot {
        &.badge-#{$name} {
            color: $color;

            &.has-bg {
                background-color: rgba($color, .15);
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .badge-dot-sm {
        width: 0;
        overflow: hidden;
    }
}

@media (max-width: 413px) {
    .badge-dot-mb {
        width: 0;
        overflow: hidden;
    }
}

@media (max-width: 369px) {
    .badge-dot-xs {
        width: 0;
        overflow: hidden;
    }
}